<template>
	<div class="pdpage">
		<div>
			<div class="prow" v-for="(item,idx) in DataList" :key="idx">
				<div style="min-height: 60px;" @click="showPrint(item)">
					<div class="picon">
						<img :src="item.file_url.indexOf('.doc')>0?'./images/doc.jpg':'./images/pdf.png'" />
					</div>
					<div class="pinfo">
						<div class="pname" style="font-weight: bold;">{{item.file_name}}</div>
						<div class="pdesc" style="color: orange;">
							<span>
								{{item.paper_type}} /
							</span>
							<span v-if="item.colors==1">
								彩色 /
							</span>
							<span v-else>
								黑白 /
							</span>
							<span v-if="item.double_face==1">
								双面打印 /
							</span>
							<span v-else>
								单面 /
							</span>
							<span>
								{{item.page}}页
							</span>
						</div>
						<div class="pstatus">
							<span style="color: seagreen;">
								{{item.tmoney}}元 / {{item.ispay==1?'已支付':''}}{{item.ispay==-1?'已退款':''}}
							</span>
							<span v-if="item.print_status==0">
								队列中
							</span>
							<span v-if="item.print_status==1">
								打印中
							</span>
							<span v-if="item.print_status==2">
								打印完成
							</span>
							<span style="color: orangered;" v-if="item.print_status==-1">
								打印失败
							</span>
						</div>
					</div>
		
				</div>
		
				<div class="chilun" v-if="!item.expried">
					<el-button size="mini" @click="rePrint(item)">重打</el-button>
					<el-button size="mini" @click="refund(item)">退款</el-button>
				</div>
		
			</div>
		
			<div v-if="DataList.length==0" style="text-align: center;line-height: 200px;">还没有配置打印机</div>
		
		</div>
		
		
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				visibleModal: false,
				easyModalShow: false,
				DataList: [],
				printer: null,
				page: {
					page: 1,
					pagesize: 20,
					count: 0
				},
				timer:0
			}
		},
		mounted() {
			document.title="我的订单"
			this.waitLogin().then(_=>{
				this.getList()
				this.timer = setInterval(()=>{
					this.refreshData()
				},3000)
			})
			
		},
		beforeUnmount() {
			clearInterval(this.timer)
		},
		methods: {
			getList() {
				this.$http.post("/api/shop_orders", {
					page: this.page.page
				}).then(res => {
					let time = new Date().getTime()
					for(let item of res.data.data){
						item.cdate = new Date( item.cdate.replace(/-/g,'/')).getTime()
						if(time-item.cdate>20*60*60*1000){
							item.expried = true
						}else{
							item.expried = false
						}
					}
					this.DataList = res.data.data
					this.page = res.data.page
				})
			},
			handlePageChange(e) {
				this.page.page = e
				this.getList()
			},
			refreshData() {
				this.page.page = 1
				this.getList()
			},
			downFile(e) {
				window.open(e.file_url, "_blank")
			},
			rePrint(e) {
				this.$$confirm({msg:"确定重打该文件？",okcb:o=>{
					this.$http.post("/api/reprint_order", {
						id: e.id
					}).then(res => {
						this.$$toast("已加入打印队列");
						this.getList()
					})
				}})
			},
			refund(e) {
				this.$$toast("功能开发中");
			}

		}
	}
</script>


<style scoped>
	.picon {
		width: 60px;
		height: 60px;
		border-radius: 8px;
		overflow: hidden;
		float: left;
	}

	.picon img {
		width: 100%;
		height: 100%;
	}

	.pinfo {
		margin-left: 70px;
	}

	.pname,
	.pdesc,
	.pstatus {
		height: 20px;
		line-height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.pstatus {
		font-size: 12px;
	}

	.pstatus span {
		padding: 0 5px;
	}

	.prow {
		margin-bottom: 10px;
		border-bottom: 1px solid #ccc;
		padding-bottom: 10px;
		position: relative;
	}

	.chilun {
		
		height: 20px;
		position: absolute;
		top: 30px;
		right: 0px;
	}

	.chilun img {
		width: 100%;
		height: 100%;
	}
</style>
